<template>
  <div>
    <div style="margin:0 0 15px 15px">
      <a-button class="add-btn" @click="addGroupShow = true">新建组</a-button>
      <RedoOutlined v-show="!loading" style="margin-left:20px;color:#0066CC" @click="loadData" />
    </div>
    <a-table :columns="columns" :row-key="record => record.id" :data-source="listData" bordered :pagination="pagination"
      :loading="loading" @change="tableChange">
      <template #groupMember="{ record }">
        <div>
          <span v-show="record.leader">{{ '组长：' + record.leader + '；' }}</span>
          <span v-show="record.member">{{ '组员：' + record.member }}</span>
        </div>
      </template>
      <template #operate="{ record }">
        <div style="display: flex;justify-content:space-around;">
          <a @click="addStaffShow = true, editData = record">添加组员</a>
          <a @click="editOperate(record)">编辑</a>
          <a @click="editJurisdiction(record)">修改权限</a>
          <a @click="dismissGroup(record)">解散组</a>
        </div>
      </template>
    </a-table>
  </div>
  <a-modal v-model:visible="addGroupShow" title="新增分组" @ok="confirmAddGroup">
    <div>
      <div>组权限<span style="color:#f00">*</span></div>
      <a-select v-model:value="groupAuthType" style="width:100%">
        <a-select-option :value="1">管理员</a-select-option>
            <a-select-option :value="2">散车</a-select-option>
            <a-select-option :value="3">批量车</a-select-option>
          </a-select>
    </div>
    <div class="m-t1">
      <div>组名<span style="color:#f00">*</span></div>
      <a-input v-model:value="addGroupName" />
    </div>
  </a-modal>
  <!-- 添加组员 -->
  <a-modal v-model:visible="addStaffShow" title="添加员工" @ok="confirmAddStaff" :maskClosable="false">
    <div>
      <a-form ref="addFormRef" :model="addStaffForm" :rules="rules" :label-col="{ span: 100 }"
        :wrapper-col="{ span: '100%' }" layout="vertical">
        <a-form-item label="选择员工" name="empId">
          <a-select v-if="addStaffShow" :filter-option="false" show-search placeholder="选择员工" @search="employerValue">
            <template v-if="flagLoading" #notFoundContent>
              <a-spin size="small" />
            </template>
            <a-select-option v-for="item in employeeOptions" :key="item.id" :value="item.name"
              @click="employeeChoose(item)">{{ item.name }}-{{ item.no }}-{{ item.mobile }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="员工分类" name="empType">
          <a-select v-model:value="addStaffForm.empType">
            <a-select-option :value="1">组长</a-select-option>
            <a-select-option :value="2">组员</a-select-option>
            <a-select-option :value="3">管理员</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </div>
  </a-modal>
  <a-modal v-model:visible="jurisdictionShow" title="修改权限" @ok="confirmJur" :maskClosable="false">
    <div >
      <div>组权限<span style="color:#f00">*</span></div>
      <a-select v-model:value="editJurVal" style="width:100%">
            <a-select-option :value="1">管理员</a-select-option>
            <a-select-option :value="2">散车</a-select-option>
            <a-select-option :value="3">批量车</a-select-option>
          </a-select>
    </div>
  </a-modal>
  <a-modal v-model:visible="editShow" title="编辑" footer="" @cancel="editCancel">
    <div>
      <a-table :columns="editColumns" :row-key="record => record.empId" :data-source="editListData" bordered
        :pagination="false" :loading="editTableLoading">
        <template #operate="{ record }">
          <a-popconfirm title="确认删除？" ok-text="确认" cancel-text="取消" @confirm="confirmDelete(record)" @cancel="cancel">
            <a>删除</a>
          </a-popconfirm>
        </template>
      </a-table>
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs, ref, onMounted,h } from 'vue'
import { employeeList } from '@/api/crmManagement/setRules'
import { addGruop, getGroupPage, addGroupMember, getEmpGroupList, deleteGroupEmp, dismissGroupOperate, editGruop} from '@/api/transport/dispatch'
import { message } from 'ant-design-vue'
import { RedoOutlined } from '@ant-design/icons-vue'
import { Modal } from 'ant-design-vue'
import { IfNull } from '@/utils/util'

export default {
  components: {
    RedoOutlined
  },
  setup () {
    const addFormRef = ref(null)
    const state = reactive({
      editJurVal:'',
      jurisdictionShow:false,
      loading: false,
      status: false,
      editShow: false,
      addGroupShow: false,
      addStaffShow: false,
      flagLoading: false,
      editTableLoading: false,
      addGroupName: '',
      groupAuthType:null,
      addStaffForm: { empType: null },
      editData: {},
      listData: [],
      employeeOptions: [],
      editListData: [],
      columns: [
        {
          title: '组名',
          dataIndex: 'groupName',
          align: 'center'
        },
        {
          title: '组权限',
          dataIndex: 'groupAuthType.label',
          align: 'center'
        },
        {
          title: '组员',
          dataIndex: 'groupMember',
          align: 'center',
          slots: {
            customRender: 'groupMember'
          }
        },
        {
          title: '创建人',
          dataIndex: 'creator',
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operate',
          width: '15%',
          align: 'center',
          slots: {
            customRender: 'operate'
          }
        },
      ],
      editColumns: [
        {
          title: '组员名称',
          dataIndex: 'empName',
          align: 'center'
        },
        {
          title: '类型',
          dataIndex: 'empType.label',
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operate',
          align: 'center',
          slots: {
            customRender: 'operate'
          }
        },
      ],
      rules: {
        empId: { required: true, message: '请选择员工', trigger: 'blur', type: 'string' },
        empType: { required: true, message: '请选择员工分类', trigger: 'blur', type: 'number' }
      },
      pagination: {
        current: 1,
        pageSize: 10,
        total: null,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    onMounted(() => {
      loadData()
    })
    
    const editJurisdiction = (record) => {
      state.editData = record
      state.editJurVal = record.groupAuthType?.value
      state.jurisdictionShow = true 
    }
    const confirmJur = () => {
      if(!state.editJurVal){
        message.error('请选择组权限')
        return
      }
      editGruop({
        id:state.editData.id,
        groupAuthType:state.editJurVal
      }).then( res => {
        if (res.code === 10000) {
            message.success('修改成功')
            state.jurisdictionShow = false
            state.editJurVal = null
            loadData()
          }
      })
    }
    const loadData = () => {
      state.loading = true
      state.listData = []
      getGroupPage({
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
          state.status = false
        }
      }).finally(() => {
        state.loading = false
      })
    }
    const tableChange = (page) => {
      state.pagination = page
      loadData()
    }
    // 添加分组
    const confirmAddGroup = () => {
      if(!state.groupAuthType){
        message.error('请选择组权限')
        return
      }
      if(!IfNull(state.addGroupName)){
        message.error('请输入组名')
        return
      }
        addGruop({
          groupName: state.addGroupName,
          groupAuthType:state.groupAuthType
        }).then(res => {
          if (res.code === 10000) {
            message.success('添加分组成功')
            state.addGroupShow = false
            state.addGroupName = ''
            state.groupAuthType = null
            loadData()
          }
        })
    
    }
    // 添加组员
    const confirmAddStaff = () => {
      addGroupMember({
        empType: state.addStaffForm.empType,
        empId: state.addStaffForm.empId,
        empName: state.addStaffForm.empName,
        groupId: state.editData.id
      }).then(res => {
        if (res.code === 10000) {
          message.success('添加成功')
          state.addStaffShow = false
          state.addStaffForm = { empType: null }
          loadData()
        }
      })
    }
    // 新建----员工搜索
    const employerValue = e => {
      if (e === '' || e === null) clearTimeout(state.timer)
      state.flagLoading = true
      if (state.timer !== null) {
        clearTimeout(state.timer)
      }
      state.timer = setTimeout(() => {
        employeeList({
          current: 1,
          name: e,
          size: 10
        }).then(res => {
          if (res.code === 10000) {
            state.employeeOptions = res.data
            state.flagLoading = false
          }
        }).catch(err => { console.log(err) })
      }, 1000)
    }
    // 新建----选择员工
    const employeeChoose = (item) => {
      state.addStaffForm.empId = item.id
      state.addStaffForm.empName = item.name
      state.employeeOptions = []
    }
    // 解散组
    const dismissGroup = (record) => {
      Modal.confirm({
        title: '',
        content: h('div', {  style:"text-align:center;font-size:18px" }, [
         '确认要解散该组吗？',
       ]),
        okText: '确认',
        cancelText: '取消',
        okType: 'danger',
        maskClosable: true,
        onOk () {
          console.log('确认解散');
          dismissGroupOperate({ groupId: record.id }).then(res => {
            if (res.code === 10000) {
              message.success('小组已被解散')
              loadData()
            }
          })
        },
        onCancel () {
          console.log('取消解散');
        }
      })
    }
    // 编辑
    const editOperate = record => {
      if (record) state.editData = record
      state.editShow = true
      state.editTableLoading = true
      getEmpGroupList({ groupId: state.editData.id }).then(res => {
        if (res.code === 10000) {
          state.editListData = res.data
          state.editTableLoading = false
        }
      })
    }
    // 删除组内成员
    const confirmDelete = record => {
      deleteGroupEmp({ id: record.id }).then(res => {
        if (res.code === 10000) {
          message.success('成员已被删除')
          state.status = true
          editOperate()
        }
      })
    }
    // 数据更新
    const editCancel = () => {
      state.editShow = false
      state.editListData = []
      if (state.status === true) loadData()
    }

    return {
      ...toRefs(state),
      addFormRef,
      loadData,
      tableChange,
      employerValue,
      confirmAddGroup,
      confirmAddStaff,
      employeeChoose,
      dismissGroup,
      editOperate,
      confirmDelete,
      editCancel,
      editJurisdiction,
      confirmJur
    }
  }
}
</script>

<style lang="less" scoped>
@import '../index.less';

.ant-modal-confirm-btns {
  :deep(.ant-btn) {
    border-radius: 0;
  }

  :deep(.ant-btn-primary) {
    background-color: #C34141;
  }
}
</style>