<template>
  <div>
    <div style="margin:0 0 15px 15px">
      <a-button class="add-btn" @click="editClick">新增线路配置</a-button>
      <RedoOutlined v-show="!loading" style="margin-left:20px;color:#0066CC" @click="loadData" />
    </div>
    <a-table :columns="columns" :row-key="record => record.id" :data-source="listData" bordered :pagination="pagination"
      :loading="loading" @change="tableChange">
      <template #lineName="{ record }">
        <div>
          {{ record.startCityName }}——{{ record.endCityName }}
        </div>
      </template>
      <template #operate="{ record }">
        <div>
          <a @click="editClick(record)">修改</a>
        </div>
      </template>
    </a-table>
  </div>
  <a-modal v-model:visible="editShow" :title="title" @ok="confirmAdd" @cancel="detailData = {}" :maskClosable="false">
    <div v-if="editShow">
      <a-form ref="addFormRef" :model="addForm" :rules="rules" :label-col="{ span: 100 }" :wrapper-col="{ span: '100%' }"
        layout="vertical">
        <a-form-item :name="['startAddress', 'city']">
          <template #label>
            <div>
              <span style="margin-right:25px">起运城市</span>
              <span v-show="detailData.startCityName">原：{{ detailData.startCityName }}</span>
            </div>
          </template>
          <a-cascader :options="$store.state.app.cityTypeDict" :show-search="{ filter }" :changeOnSelect="true"
            @change="setStartAddress" placeholder="选择起始地" style="width:100%" />
        </a-form-item>
        <a-form-item :name="['endAddress', 'city']">
          <template #label>
            <div>
              <span style="margin-right:25px">终点城市</span>
              <span v-show="detailData.endCityName">原：{{ detailData.endCityName }}</span>
            </div>
          </template>
          <a-cascader style="width:100%" :show-search="{ filter }" :options="$store.state.app.cityTypeDict"
            :changeOnSelect="true" @change="setEndAddress" placeholder="选择目的地" />
        </a-form-item>
        <a-form-item name="carrierId">
          <template #label>
            <div>
              <span style="margin-right:25px">归属门店</span>
              <span v-show="detailData.carrierName">原：{{ detailData.carrierName }}</span>
            </div>
          </template>
          <a-select placeholder="搜索并选择承运商" @search="fetchCarrier" labelInValue :filterOption="false" show-search>
            <template v-if="fetching" #notFoundContent>
              <a-spin size="small" />
            </template>
            <a-select-option v-for="item in carriers" :key="item.id" @click="setBelongShop(item)">
              {{ item.name }}
            </a-select-option>
          </a-select>
          <!-- <a-select ref="select" v-model:value="addForm.belongShop" style="width: 120px" @focus="focus"
            @change="setBelongShop">
            <a-select-option :value="1">门店1</a-select-option>
            <a-select-option :value="2">门店2</a-select-option>
          </a-select> -->
        </a-form-item>
      </a-form>
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs, ref, onMounted } from 'vue'
import { message } from 'ant-design-vue'
import { RedoOutlined } from '@ant-design/icons-vue'
import { fuzzy } from '@/api/crm/carrer/carrier'
import { routeDeplotPage, routeDeplotEdit } from '@/api/transport/dispatch'

export default {
  components: {
    RedoOutlined
  },
  setup () {
    const addFormRef = ref(null)
    const state = reactive({
      editShow: false,
      loading: false,
      fetching: false,
      lineTypeState: true,
      timeOutId: null,
      title: '',
      listData: [],
      carriers: [],
      mirrorForm: {},
      detailData: {},
      addForm: {
        startAddress: {},
        endAddress: {},
        carrierId: '',
        carrierName: ''
      },
      rules: {
        startAddress: {
          city: {
            required: true,
            message: '请选择起运地',
            trigger: 'blur',
            type: 'string'
          }
        },
        endAddress: {
          city: {
            required: true,
            message: '请选择目的地',
            trigger: 'blur',
            type: 'string'
          }
        },
        carrierId: {
          required: true,
          message: '请选择归属门店',
          trigger: 'blur',
          type: 'string'
        }
      },
      columns: [
        {
          title: '线路名称',
          align: 'center',
          slots: {
            customRender: 'lineName'
          }
        },
        {
          title: '归属门店',
          dataIndex: 'carrierName',
          align: 'center'
        },
        {
          title: '添加人',
          dataIndex: 'operator',
          align: 'center'
        },
        {
          title: '添加时间',
          dataIndex: 'createTime',
          align: 'center'
        },
        {
          title: '操作',
          align: 'center',
          slots: {
            customRender: 'operate'
          }
        }
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        total: null,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    const fetchCarrier = value => {
      if (value === '' || value === null) {
        clearTimeout(state.timeOutId)
        state.fetching = false
      }
      if (value.length > 1) {
        state.fetching = true
        if (state.timeOutId !== null) {
          clearTimeout(state.timeOutId)
        }
        state.timeOutId = setTimeout(() => {
          fuzzy({ name: value, carrierType: 1 }).then(res => {
            if (res.code === 10000) {
              state.carriers = res.data
            } else state.carriers = []
            state.fetching = false
          })
        }, 1000)
      } else {
        state.carriers = []
      }
    }
    onMounted(() => {
      loadData()
      state.mirrorForm = JSON.stringify(state.addForm)
    })
    const loadData = () => {
      state.loading = true
      routeDeplotPage({
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
          state.loading = false
        }
      })
    }
    const tableChange = (page) => {
      state.pagination = page
      loadData()
    }
    const setStartAddress = (e, v) => {
      state.addForm.startAddress.province = e[0]
      state.addForm.startAddress.city = e[1]
      state.addForm.startAddress.area = e[2]
    }
    const setEndAddress = (e, v) => {
      state.addForm.endAddress.province = e[0]
      state.addForm.endAddress.city = e[1]
      state.addForm.endAddress.area = e[2]
    }
    const editClick = record => {
      if (record.id) {
        state.title = '修改线路配置'
        state.detailData = record
        state.addForm.id = record.id
      } else {
        state.title = '新增线路配置'
      }
      state.editShow = true
    }
    const setBelongShop = item => {
      state.addForm.carrierId = item.id
      state.addForm.carrierName = item.name
    }
    const confirmAdd = () => {
      addFormRef.value.validate().then(() => {
        routeDeplotEdit(state.addForm).then(res => {
          if (res.code === 10000) {
            message.success('配置添加成功')
            state.editShow = false
            state.addForm = JSON.parse(state.mirrorForm)
            loadData()
          }
        })
      })
    }

    return {
      ...toRefs(state),
      addFormRef,
      loadData,
      tableChange,
      setStartAddress,
      setEndAddress,
      setBelongShop,
      confirmAdd,
      fetchCarrier,
      editClick
    }
  }
}
</script>

<style lang="less" scoped>
@import '../index.less';
</style>
