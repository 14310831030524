<template>
  <div>
    <div>
      <div style="margin: 0 0 15px 15px">
        <a-button class="add-btn" @click="editClick">新增批量车归属</a-button>
        <RedoOutlined v-show="!loading" style="margin-left:20px;color:#0066CC" @click="loadData" />
      </div>
      <a-table :columns="columns" :row-key="record => record.id" :data-source="listData" bordered :pagination="pagination"
        :loading="loading" @change="tableChange">
        <template #detail="{ record }">
          <div>
            <span>订单车辆台数>={{ record.number || 0 }}</span>
          </div>
        </template>
        <template #operate="{ record }">
          <div>
            <a @click="editClick(record)">修改</a>
          </div>
        </template>
      </a-table>
    </div>
  </div>
  <a-modal v-model:visible="editShow" :title="title" @ok="confirmAdd" @cancel="editForm = JSON.parse(mirrorEditForm)" :maskClosable="false">
    <div>
      <a-form ref="addFormRef" :model="editForm" :rules="rules" :label-col="{ span: 100 }" :wrapper-col="{ span: '100%' }"
        layout="vertical">
        <a-form-item label="订单车大于或等于(辆)">
          <a-input v-model:value="editForm.number" type="number" />
        </a-form-item>
        <a-form-item name="carrierId" v-if="editShow">
        <template #label>
          <div>
            <span style="margin-right:15px">归属承运商</span>
            <span v-show="editForm.id">{{ editForm.carrierName || '' }}</span>
          </div>
        </template>
          <a-select placeholder="搜索并选择承运商" @search="fetchCarrier" labelInValue :filterOption="false" show-search>
            <template v-if="fetching" #notFoundContent>
              <a-spin size="small" />
            </template>
            <a-select-option v-for="item in carriers" :key="item.id" @click="setBelongShop(item)">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs, ref, onMounted } from 'vue'
import { RedoOutlined } from '@ant-design/icons-vue'
import { batchVehiclePage, batchVehicleEdit } from '@/api/transport/dispatch'
import { fuzzy } from '@/api/crm/carrer/carrier'
import { message } from 'ant-design-vue'

export default {
  components: {
    RedoOutlined
  },
  setup () {
    const addFormRef = ref(null)
    const state = reactive({
      editShow: false,
      loading: false,
      fetching: false,
      timeOutId: null,
      title: '新增批量车归属',
      mirrorEditForm: {},
      editForm: {
        id: '',
        number: 0,
        carrierId: '',
        carrierName: ''
      },
      listData: [],
      carriers: [],
      rules: {
        carrierId: {
          required: true,
          message: '请选择归属门店',
          trigger: 'blur',
          type: 'string'
        }
      },
      columns: [
        {
          title: '详情',
          align: 'center',
          slots: {
            customRender: 'detail'
          }
        },
        {
          title: '归属承运商',
          dataIndex: 'carrierName',
          align: 'center'
        },
        {
          title: '添加人',
          dataIndex: 'operator',
          align: 'center'
        },
        {
          title: '添加时间',
          dataIndex: 'createTime',
          align: 'center'
        },
        {
          title: '操作',
          align: 'center',
          slots: {
            customRender: 'operate'
          }
        }
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        total: null,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    onMounted(() => {
      loadData()
      state.mirrorEditForm = JSON.stringify(state.editForm)
    })
    const tableChange = (page) =>{
      state.pagination = page
      loadData()
    }
    const loadData = () => {
      state.loading = true
      batchVehiclePage({
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.loading = false
        }
      })
    }
    const editClick = record => {
      if (record.id) {
        state.editForm = record
        state.title = '修改批量车归属配置'
        state.editShow = true
      } else {
        state.title = '新增批量车归属配置'
        state.editShow = true
      }
    }
    const fetchCarrier = value => {
      if (value === '' || value === null) clearTimeout(state.timeOutId), state.fetching = false
      if (value.length > 1) {
        state.fetching = true
        if (state.timeOutId !== null) {
          clearTimeout(state.timeOutId)
        }
        state.timeOutId = setTimeout(() => {
          fuzzy({ name: value, carrierType: 1 }).then(res => {
            if (res.code === 10000) {
              state.carriers = res.data
            } else state.carriers = []
            state.fetching = false
          })
        }, 1000)
      } else {
        state.carriers = []
      }
    }
    const setBelongShop = item => {
      state.editForm.carrierId = item.id
      state.editForm.carrierName = item.name
    }
    const confirmAdd = () => {
      addFormRef.value.validate().then(res => {
        batchVehicleEdit({ ...state.editForm }).then(res => {
          if (res.code === 10000) {
            if (state.editForm.id) message.success('修改成功')
            else message.success('添加成功')
            loadData()
            state.editForm = JSON.parse(state.mirrorEditForm)
            state.editShow = false
          }
        })
      })
    }

    return {
      ...toRefs(state),
      addFormRef,
      editClick,
      tableChange,
      loadData,
      setBelongShop,
      fetchCarrier,
      confirmAdd
    }
  }
}
</script>

<style lang="less" scoped>
@import '../index.less';
</style>