<template>
  <div>
    <div style="margin:0 0 15px 15px">
      <a-button class="add-btn" @click="addShow = true">新增标签</a-button>
      <RedoOutlined v-show="!loading" style="margin-left:20px;color:#0066CC" @click="loadData" />
    </div>
    <a-table :columns="columns" :row-key="record => record.id" :data-source="listData" bordered :pagination="pagination" @change="tableChange" :loading="loading">
      <template #status="{ record }">
        <div style="display: flex;justify-content:space-around;">
          <a-popconfirm :title="title" ok-text="确认" cancel-text="取消" @confirm="changeStatus(record)">
            <a-switch @change="changeSwitch" :checked="record.status?.value" :unCheckedValue="0" :checkedValue="1" />
          </a-popconfirm>
        </div>
      </template>
    </a-table>
  </div>
  <a-modal destroyOnClose v-model:visible="addShow" title="新增标签" @ok="confirmAdd" :confirmLoading="btnLoading">
    <div>
      <a-form ref="addFormRef" :model="searchForm" :rules="rules" :label-col="{ span: 100 }" layout="vertical">
        <a-form-item label="标签名称" name="name">
          <a-input v-model:value="searchForm.name" placeholder="请输入" />
        </a-form-item>
      </a-form>
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs, ref, onMounted } from 'vue'
import { page, labelAdd, labelIsEnable } from '@/api/transport/labelDeploy'
import { message } from 'ant-design-vue'
import { RedoOutlined } from '@ant-design/icons-vue'

export default {
  components: {
    RedoOutlined
  },
  setup () {
    const addFormRef = ref(null)
    const state = reactive({
      title: '',
      btnLoading: false,
      loading: false,
      addShow: false,
      listData: [],
      searchForm: {
        name: '',
      },
      columns: [
        {
          title: '标签名称',
          dataIndex: 'name',
          align: 'center'
        },
        {
          title: '创建人',
          dataIndex: 'creator',
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          align: 'center'
        },
        {
          title: '状态',
          dataIndex: 'status',
          width: '15%',
          align: 'center',
          slots: {
            customRender: 'status'
          }
        }
      ],
      rules: {
        name: [{ required: true, message: '请输入标签名称', trigger: 'blur', type: 'string' },
        { max: 4, message: '标签字数最多为4字', trigger: 'blur' }
        ],
      },
      pagination: {
        current: 1,
        pageSize: 10,
        total: null,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    onMounted(() => {
      loadData()
    })
    const tableChange = (page) => {
      state.pagination = page
      loadData()
    }
    const changeSwitch = (e) => {
      if (e === 1) {
        state.title = '确定要打开吗？'
      } else {
        state.title = '确定要关闭吗？'
      }
    }
    const changeStatus = (record) => {
      labelIsEnable(
        record.id
      ).then(res => {
        if (res.code !== 10000) return
        record.status.value = record.status.value === 0 ? 1 : 0
        message.success('操作成功')
      })
    }
    const loadData = () => {
      state.loading = true
      page({
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
        }
      }).finally(() => {
        state.loading = false
      })
    }

    // 添加
    const confirmAdd = () => {
      addFormRef.value.validate().then(() => {
        state.btnLoading = true
        labelAdd({
          ...state.searchForm
        }).then(res => {
          if (res.code !== 10000) return
          message.success('添加成功')
          state.addShow = false
          loadData()
        }).finally(() => {
          state.btnLoading = false
        })
      })
    }





    return {
      ...toRefs(state),
      addFormRef,
      loadData,
      confirmAdd,
      changeStatus,
      changeSwitch,
      tableChange
    }
  }
}
</script>

<style lang="less" scoped>
@import '../index.less';

.ant-modal-confirm-btns {
  :deep(.ant-btn) {
    border-radius: 0;
  }

  :deep(.ant-btn-primary) {
    background-color: #c34141;
  }
}
</style>
