<template>
  <div>
    <div style="margin:0 0 15px 15px">
      <a-button class="add-btn" @click="addShow = true">新建共享配置</a-button>
      <RedoOutlined v-show="!loading" style="margin-left:20px;color:#0066CC" @click="loadData" />
    </div>
    <a-table :columns="columns" :row-key="record => record.id" :data-source="listData" bordered :pagination="pagination"
      :loading="loading" @change="tableChange">
      <template #operate="{ record }">
        <div style="display: flex;justify-content:space-around;">
          <a-popconfirm title="确认是否删除?" ok-text="确认" cancel-text="取消" @confirm="deleteShareLineId(record.id)">
            <a>删除</a>
          </a-popconfirm>
        </div>
      </template>
    </a-table>
  </div>
  <a-modal destroyOnClose v-model:visible="addShow" title="新增分组" @cancel="cancelSearchForm" @ok="confirmAdd">
    <div>
      <a-form ref="addFormRef" :model="searchForm" :rules="rules" :label-col="{ span: 100 }"
             layout="vertical">
        <a-form-item label="选择起运省份" name="startProvince">
          <a-cascader  v-model:value="start" :options="$store.state.app.OnlyCityList" :show-search="{ filter }"
                      :changeOnSelect="true" @change="setStartAddress" placeholder="选择起始地" />
        </a-form-item>
        <a-form-item label="选择终点省份" name="endProvince">
          <a-cascader  v-model:value="end" :options="$store.state.app.OnlyCityList" :show-search="{ filter }"
                      :changeOnSelect="true" @change="setEndAddress" placeholder="选择目的地" />
        </a-form-item>
      </a-form>
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs, ref, onMounted } from 'vue'
import { addShareLine, getShareLinePage, deleteShareLine } from '@/api/transport/dispatch'
import { message } from 'ant-design-vue'
import { RedoOutlined } from '@ant-design/icons-vue'

export default {
  components: {
    RedoOutlined
  },
  setup () {
    const addFormRef = ref(null)
    const state = reactive({
      loading: false,
      addShow: false,
      listData: [],
      start: [],
      end: [],
      searchForm: {},
      columns: [
        {
          title: '线路名称',
          dataIndex: 'lineName',
          align: 'center'
        },
        {
          title: '配置人',
          dataIndex: 'operator',
          align: 'center'
        },
        {
          title: '配置时间',
          dataIndex: 'createTime',
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operate',
          width: '15%',
          align: 'center',
          slots: {
            customRender: 'operate'
          }
        }
      ],
      rules: {
        startProvince: { required: true, message: '请选择起点省', trigger: 'blur', type: 'string' },
        endProvince: { required: true, message: '请选终点省份', trigger: 'blur', type: 'string' }
      },
      pagination: {
        current: 1,
        pageSize: 10,
        total: null,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    onMounted(() => {
      loadData()
    })
    const loadData = () => {
      cancelSearchForm()
      state.loading = true
      state.listData = []
      getShareLinePage({
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
        }
      }).finally(() => {
        state.loading = false
      })
    }
    const tableChange = (page) => {
      state.pagination = page
      loadData()
    }
    // 添加
    const confirmAdd = () => {
      addFormRef.value.validate().then(() => {
        addShareLine({
          endProvince: state.searchForm.endProvince,
          endProvinceName: state.searchForm.endProvinceNmae,
          startProvince: state.searchForm.startProvince,
          startProvinceName: state.searchForm.startProvinceNmae
        }).then(res => {
          if (res.code === 10000) {
            message.success('添加成功')
            state.addShow = false
            loadData()
          }
        })
      })
    }

    const setStartAddress = (value, selectedOptions) => {
      state.searchForm.startProvince = selectedOptions[0].pid
      state.searchForm.startProvinceNmae = selectedOptions[0].label
    }

    const setEndAddress = (value, selectedOptions) => {
      state.searchForm.endProvince = selectedOptions[0].pid
      state.searchForm.endProvinceNmae = selectedOptions[0].label
    }

    const cancelSearchForm = () => {
      state.searchForm.endProvince = null
      state.searchForm.startProvince = null
      state.searchForm.startProvince = null
      state.searchForm.endProvinceNmae = null
      state.start= []
      state.end= []
    }
    const deleteShareLineId = (id) => {
      deleteShareLine({
        id: id
      }).then(res => {
        if (res.code === 10000) {
          message.success('删除成功')
          state.addGroupName = ''
          loadData()
        }
      })
    }
    return {
      ...toRefs(state),
      addFormRef,
      loadData,
      tableChange,
      confirmAdd,
      setStartAddress,
      setEndAddress,
      deleteShareLineId,
      cancelSearchForm
    }
  }
}
</script>

<style lang="less" scoped>
@import '../index.less';

.ant-modal-confirm-btns {
  :deep(.ant-btn) {
    border-radius: 0;
  }

  :deep(.ant-btn-primary) {
    background-color: #C34141;
  }
}
</style>
