<template>
  <div style="width:100%;height:100%;background:#fff;">
    <div style="margin:10px">
      <a-button class="add-btn" @click="addShow = true">新增排班配置</a-button>
      <RedoOutlined v-show="!loading" style="margin-left:20px;color:#0066CC" @click="loadData" />
    </div>
    <div>
      <a-table :columns="columns" :row-key="record => record.id" :data-source="listData" bordered :pagination="pagination"
        :loading="loading" @change="tableChange">
        <template #isWork="{ record }">
          <div>
            <a-switch :disabled="record.disabled" checked-children="上班" un-checked-children="休息" :checkedValue="1"
              :unCheckedValue="0" v-model:checked="record.isWork.value" />
          </div>
        </template>
        <template #successor="{ record }">
          <div v-show="record.isWork?.value === 0" style="display:flex;align-items: center;" @click="editData = record">
            <div style="margin-right:15px">{{ record.substitute }}</div>
            <a-select v-if="!loading" style="flex:1" :disabled="record.disabled" :filter-option="false" show-search
              :placeholder="record.substitute ? '更改替班人' : '选择替班人'" @search="employerValue">
              <template v-if="flagLoading" #notFoundContent>
                <a-spin size="small" />
              </template>
              <a-select-option v-for="item in employeeOptions" :key="item.id" :value="item.name"
                @click="employeeChoose(item, true)">{{ item.name }}-{{ item.no }}-{{ item.mobile }}</a-select-option>
            </a-select>
          </div>
        </template>
        <template #operate="{ record }">
          <div>
            <a-popconfirm title="确认保存" ok-text="确认" cancel-text="取消" @cancel="cancelConfirm(record)" @confirm="editSave(record)">
              <a v-show="!record.disabled">保存</a>
            </a-popconfirm>
            <a v-show="record.disabled" @click="record.disabled = !record.disabled,editData=record">修改</a>
          </div>
        </template>
      </a-table>
    </div>
  </div>
  <a-modal v-model:visible="addShow" title="新增排班配置" @ok="confirmAdd" @cancel="closeWindow" :maskClosable="false">
    <a-form v-if="addShow" ref="addFormRef" :model="addForm" :rules="rules" :label-col="{ span: 100 }"
      :wrapper-col="{ span: '100%' }" layout="vertical">
      <a-form-item label="选择员工" name="empId">
        <a-select :filter-option="false" show-search placeholder="选择员工" @search="employerValue">
          <template v-if="flagLoading" #notFoundContent>
            <a-spin size="small" />
          </template>
          <a-select-option v-for="item in employeeOptions" :key="item.id" :value="item.name"
            @click="employeeChoose(item)">{{ item.name }}-{{ item.no }}-{{ item.mobile }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="选择状态" name="isWork">
        <a-select ref="select" v-model:value="addForm.isWork" @change="isWorkChange">
          <a-select-option :value="1">上班</a-select-option>
          <a-select-option :value="0">休息</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item v-show="addForm.isWork === 0" label="替班人" name="substituteId">
        <a-select :filter-option="false" show-search placeholder="选择替班人" @search="substituteValue">
          <template v-if="substituteLoading" #notFoundContent>
            <a-spin size="small" />
          </template>
          <a-select-option v-for="item in substituteOptions" :key="item.id" :value="item.name"
            @click="substituteChoose(item)">{{ item.name }}-{{ item.no }}-{{ item.mobile }}</a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { reactive, toRefs, ref, onMounted } from 'vue'
import { employeeList } from '@/api/crmManagement/setRules'
import { scheduleEdit, schedulePage } from '@/api/transport/dispatch'
import { message } from 'ant-design-vue'
import { RedoOutlined } from '@ant-design/icons-vue'
// import { object } from 'vue-types'

export default {
  components: {
    RedoOutlined
  },
  setup () {
    const addFormRef = ref(null)
    const state = reactive({
      loading: false,
      addShow: false,
      flagLoading: false,
      substituteLoading: false,
      timer: null,
      timerTow: null,
      addForm: {
        empName: '',
        empId: '',
        isWork: null,
        substitute: '',
        substituteId: ''
      },
      mirrorAddForm: {},
      editData: {},
      listData: [],
      employeeOptions: [],
      substituteOptions: [],
      rules: {
        empId: {
          required: true,
          message: '请选择员工',
          trigger: 'blur',
          type: 'string'
        },
        isWork: {
          required: true,
          message: '请选择状态',
          trigger: 'blur',
          type: 'number'
        },
        substituteId: {
          required: true,
          message: '请选择替班人',
          trigger: 'blur',
          type: 'string'
        }
      },
      columns: [
        {
          title: '员工姓名',
          dataIndex: 'empName',
          width: '10%',
          align: 'center'
        },
        {
          title: '上班状态',
          dataIndex: 'isWork',
          width: '10%',
          slots: {
            customRender: 'isWork'
          }
        },
        {
          title: '替班人',
          dataIndex: 'successor',
          width: '10%',
          slots: {
            customRender: 'successor'
          }
        },
        {
          title: '操作',
          dataIndex: 'operate',
          width: '10%',
          slots: {
            customRender: 'operate'
          }
        }
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        total: null,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    onMounted(() => {
      loadData()
      state.mirrorAddForm = JSON.stringify(state.addForm)
    })
    const tableChange = page => {
      state.pagination = page
      loadData()
    }
    const loadData = () => {
      state.loading = true
      schedulePage({
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.pagination.total = res.data.total
          res.data.records.forEach(item => {
            item.disabled = true
          })
          state.listData = res.data.records
          state.loading = false
        }
      })
    }
    const isWorkChange = (e, v) => {
      if (e === 0) state.rules.substituteId.required = true
      else state.rules.substituteId.required = false
    }
    // 修改---员工搜索
    const employerValue = e => {
      if (e === '' || e === null) clearTimeout(state.timer)
      state.flagLoading = true
      if (state.timer !== null) {
        clearTimeout(state.timer)
      }
      state.timer = setTimeout(() => {
        employeeList({
          current: 1,
          name: e,
          size: 10
        }).then(res => {
          if (res.code === 10000) {
            state.employeeOptions = res.data
            state.flagLoading = false
          }
        }).catch(err => { console.log(err) })
      }, 1000)
    }
    // 修改---选择员工
    const employeeChoose = (item, status) => {
      // status为true===修改   否则为新增
      if (status) {
        state.editData.substitute = item.name
        state.editData.substituteId = item.id
        state.employeeOptions = []
      } else {
        state.addForm.empId = item.id
        state.addForm.empName = item.name
        state.employeeOptions = []
      }
    }
    // 新增---替班人搜索
    const substituteValue = e => {
      if (e === '' || e === null) clearTimeout(state.timerTow)
      state.substituteLoading = true
      if (state.timerTow !== null) {
        clearTimeout(state.timerTow)
      }
      state.timerTow = setTimeout(() => {
        employeeList({
          current: 1,
          name: e,
          size: 10
        }).then(res => {
          if (res.code === 10000) {
            state.substituteOptions = res.data
            state.substituteLoading = false
          }
        }).catch(err => { console.log(err) })
      }, 1000)
    }
    // 新增---选择替班人
    const substituteChoose = (item) => {
      state.addForm.substituteId = item.id
      state.addForm.substitute = item.name
      state.substituteOptions = []
    }
    // 确认新增
    const confirmAdd = () => {
      addFormRef.value.validate().then(res => {
        scheduleEdit(state.addForm).then(res => {
          if (res.code === 10000) {
            message.success('新增成功')
            loadData()
            closeWindow()
          }
        })
      })
    }
    // 关闭新增弹窗
    const closeWindow = () => {
      state.addShow = false
      state.employeeOptions = []
      state.addForm = JSON.parse(state.mirrorAddForm)
    }
    // 确认修改
    const editSave = (record) => {
      scheduleEdit(state.editData).then(res => {
        if (res.code === 10000) {
          message.success('修改成功')
          state.editData = {}
          loadData()
        }
      })
    }
    // 取消修改
    const cancelConfirm = record => {
      record.disabled = true
    }

    return {
      ...toRefs(state),
      addFormRef,
      editSave,
      loadData,
      tableChange,
      isWorkChange,
      employerValue,
      employeeChoose,
      substituteValue,
      substituteChoose,
      confirmAdd,
      closeWindow,
      cancelConfirm
    }
  }
}
</script>

<style lang="less" scoped>
@import '../index.less';
</style>
