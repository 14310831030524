<template>
  <div style="background:#fff">
    <a-tabs>
      <a-tab-pane key="1" tab="部门配置">
        <DepartmentDeploy />
      </a-tab-pane>
      <!-- <a-tab-pane key="2" tab="线路配置">
        <RouteDeploy />
      </a-tab-pane>
      <a-tab-pane key="3" tab="批量车配置">
        <BatchVehicleDeploy />
      </a-tab-pane>
      <a-tab-pane key="4" tab="员工排班配置">
        <SchedulingManage />
      </a-tab-pane> -->
      <a-tab-pane key="5" tab="分组配置">
        <GroupDeploy />
      </a-tab-pane>
      <a-tab-pane key="6" tab="共享配置">
        <ShareDeploy />
      </a-tab-pane>
      <a-tab-pane key="7" tab="标签配置">
        <LabelDeploy />
      </a-tab-pane>
    </a-tabs>
  </div>
  <!-- 新增部门 -->
  <a-modal v-model:visible="editShow" :title="windowTitle" @ok="confirmEdit" @cancel="closeWindow">
    <div>
      选择部门<span style="color:#f00">*</span>
    </div>
    <a-form-item label="">
      <a-select v-model:value="dataForm.orgName" :show-search="true" @change="orgChange">
        <a-select-option :value="item.name" v-for="item in orgList" :key="item.id">{{ item.name }}</a-select-option>
      </a-select>
    </a-form-item>
    <div>
      调度模式<span style="color:#f00">*</span>
    </div>
    <a-form-item label="">
      <a-select v-model:value="dataForm.dispatchType">
        <a-select-option :value="1">运力调度</a-select-option>
        <a-select-option :value="2">部门直调</a-select-option>
      </a-select>
    </a-form-item>
  </a-modal>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
import RouteDeploy from './routeDeploy/routeDeploy.vue'
import DepartmentDeploy from './departmentDeploy/departmentDeploy.vue'
import BatchVehicleDeploy from './batchVehicleDeploy/batchVehicleDeploy.vue'
import SchedulingManage from './schedulingManage/schedulingManage.vue'
import GroupDeploy from './groupDeploy/groupDeploy.vue'
import ShareDeploy from './shareDeploy/shareDeploy.vue'
import LabelDeploy from './labelDeploy' 
export default {
  components: {
    RouteDeploy,
    DepartmentDeploy,
    BatchVehicleDeploy,
    SchedulingManage,
    GroupDeploy,
    ShareDeploy,
    LabelDeploy
  },
  setup () {
    const state = reactive({

    })
    onMounted(() => {

    })

    return {
      ...toRefs(state),
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
