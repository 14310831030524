<template>
  <div style="background:#fff">
    <div style="margin: 0 0 15px 15px">
      <a-button class="add-btn" @click="editClick">新增业务调度</a-button>
      <RedoOutlined v-show="!loading" style="margin-left:20px;color:#0066CC" @click="loadData" :disabled="true" />
    </div>
    <div>
      <a-table :columns="columns" :row-key="record => record.id" :data-source="listData" bordered :pagination="pagination"
        :loading="loading" @change="tableChange">
        <template #operate="{ record }">
          <div>
            <a @click="editClick(record)">修改</a>
          </div>
        </template>
      </a-table>
    </div>
  </div>
  <!-- 新增部门 -->
  <a-modal v-model:visible="editShow" :title="windowTitle" @ok="confirmEdit" @cancel="closeWindow" :maskClosable="false">
    <div>
      选择部门<span style="color:#f00">*</span>
    </div>
    <a-form-item label="">
      <a-select :disabled="dataDetail.id" v-model:value="dataForm.orgName" :show-search="true" @change="orgChange">
        <a-select-option :value="item.name" v-for="item in orgList" :key="item.id">{{ item.name }}</a-select-option>
      </a-select>
    </a-form-item>
    <div>
      调度模式<span style="color:#f00">*</span>
    </div>
    <a-form-item label="">
      <a-select v-model:value="dataForm.dispatchType">
        <a-select-option :value="1">华月业务</a-select-option>
        <a-select-option :value="2">自营业务</a-select-option>
        <a-select-option :value="3">车小象业务</a-select-option>
      </a-select>
    </a-form-item>
  </a-modal>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
import { message } from 'ant-design-vue'
import { RedoOutlined } from '@ant-design/icons-vue'
import { dispathPage, dispathEdit } from '@/api/transport/dispatch'
import { getOrgList } from '@/api/global'

export default {
  components: {
    RedoOutlined
  },
  setup () {
    const state = reactive({
      windowTitle: '',
      loading: false,
      editShow: false,
      orgList: [],
      listData: [],
      dataDetail: {},
      mirrorDataForm: {},
      dataForm: {
        orgId: '',
        orgName: '',
        dispatchType: ''
      },
      columns: [
        {
          title: '部门名称',
          dataIndex: 'orgName',
          align: 'center'
        },
        {
          title: '调度类型',
          dataIndex: 'dispatchType.label',
          align: 'center'
        },
        {
          title: '添加人',
          dataIndex: 'operator',
          align: 'center'
        },
        {
          title: '添加时间',
          dataIndex: 'createTime',
          align: 'center'
        },
        {
          title: '操作',
          align: 'center',
          slots: {
            customRender: 'operate'
          }
        },
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        total: null,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    onMounted(() => {
      loadData()
      state.mirrorDataForm = JSON.stringify(state.dataForm)
      getOrgList({ parentOrgId: 0 }).then(res => {
        state.orgList = res.data
      })
    })
    const tableChange = (e, v) => {
      state.pagination = e
      loadData()
    }
    const loadData = () => {
      state.loading = true
      dispathPage({
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
          state.loading = false
        }
      })
    }
    const editClick = item => {
      if (item.id !== undefined) {
        state.windowTitle = '修改调度权限'
        state.dataDetail = item
        state.dataForm.orgId = item.orgId
        state.dataForm.orgName = item.orgName
        state.dataForm.dispatchType = item.dispatchType?.value
      } else state.windowTitle = '新增部门'
      state.editShow = true
    }
    const orgChange = (e, v) => {
      state.dataForm.orgId = v.key
    }
    const closeWindow = () => {
      state.windowTitle = ''
      state.dataDetail = {}
      state.dataForm = JSON.parse(state.mirrorDataForm)
      state.editShow = false
    }
    const confirmEdit = () => {
      if (!state.dataDetail.id) {
        if (state.dataForm.orgName !== '' && state.dataForm.dispatchType !== '') requestAPI()
        else {
          if (state.dataForm.orgName == '') message.error('请选择部门')
          if (state.dataForm.dispatchType == '') message.error('请选择调度模式')
        }
      }else{
        if(state.dataForm.dispatchType == state.dataDetail.dispatchType?.value) message.error('请更改调度模式')
        else requestAPI()
      }
      function requestAPI () {
        dispathEdit({
          ...state.dataForm,
          id: state.dataDetail === {} ? null : state.dataDetail.id
        }).then(res => {
          if (res.code === 10000) {
            message.success(state.dataDetail.id !== undefined ? '修改成功' : '新增成功')
            loadData()
            closeWindow()
          }
        })
      }
    }

    return {
      ...toRefs(state),
      loadData,
      tableChange,
      editClick,
      orgChange,
      closeWindow,
      confirmEdit
    }
  }
}
</script>

<style lang="less" scoped>
@import '../index.less';
</style>